<!-- 请选择合作服务商类型弹窗 -->
<template>
  <div>
    <el-dialog
        width="30%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'修改':'添加'"
        @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="110px"
        class="ele-form-search">
        <el-form-item label="营业执照:" prop="business_license" style="margin-bottom: 25px;">
          <el-upload
              :limit="1"
              :class="{hide_box: businessLicenseupload_btn}"
              :on-change="businessLicensechange"
              :on-success="successUpload2"
              :action="action"
              list-type="picture-card"
              accept=".jpg,.png,.pdf"
              :on-preview="businessLicensePreview"
              :on-remove="businessLicensehandleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="businessLicensedialogVisible">
            <img width="100%" :src="businessLicensedialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <el-form-item label="信用代码:" prop="credit_code" style="margin-bottom: 25px;">
          <el-input
              clearable
              v-model="form.credit_code"
              placeholder="请输入信用代码"/>
        </el-form-item>
        <el-form-item label="企业全称:" prop="company_name" style="margin-bottom: 25px;">
          <el-input
            clearable
            v-model="form.company_name"
            placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="企业简称:" prop="company_name" style="margin-bottom: 25px;">
          <el-input
            clearable
            v-model="form.company_name1"
            placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="法人身份证:" prop="realname" style="margin-bottom: 25px;">
          上传
        </el-form-item>
        <el-form-item label="法人姓名:" prop="realname" style="margin-bottom: 25px;">
          <el-input
            clearable
            v-model="form.realname"
            placeholder="请输入法人姓名"/>
        </el-form-item>
        <el-form-item label="法人手机号:" prop="phone" style="margin-bottom: 25px;">
          <el-input
            clearable
            v-model="form.phone"
            placeholder="请输入法人手机号"/>
        </el-form-item>
        <el-form-item label="登录密码:" prop="password" style="margin-bottom: 25px;">
          <el-input
              clearable
              show-password
              v-model="form.password"
              placeholder="请输入登录密码"/>
        </el-form-item>
        <el-form-item label="结算方式:" prop="settlement_mode" style="margin-bottom: 25px;">
          <el-select
            clearable
            v-model="form.settlement_mode"
            placeholder="请选择"
            class="ele-fluid">
            <el-option
              v-for="(item) in dict.type.valuationclearing_form"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="省市区:" prop="registration_place" style="margin-bottom: 25px;">
          <el-cascader
              ref="refCascader"
              :options="city"
              v-model="form.registration_place"
              @change="handleChange"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址:" prop="detailed_address" style="margin-bottom: 25px;">
          <el-input
            clearable
            v-model="form.detailed_address"
            placeholder="请输入详细地址"/>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>

  </div>

</template>
<script>
// 引入的接口
import {Addsave_facilitator,getcityList,ocr} from "@/api/yunli";
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

export default {
  name: '',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    dict: Object,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: {
        credit_code:'',
        company_name: '',
        detailed_address: '',
        realname:''
      },
      // 验证规则
      rules: {
        company_name: [
          { required: true, message: '请输入服务商名称', trigger: 'blur' },
          { min: 0, max: 50, message: '长度最多输入50个字符', trigger: 'blur' }
        ],
        credit_code:[
          { required: true, message: '请输入信用代码', trigger: 'blur' },
          { min: 0, max: 18, message: '长度最多输入18个字符', trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        phone:[
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        settlement_mode:[
          { required: true, message: '请选择结算方式', trigger: 'change' },
        ],
        registration_place: [
          { required: true, message: '请选择区域', trigger: 'registration_place' },
        ],
        detailed_address:[
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          { min: 0, max: 60, message: '长度最多输入60个字符', trigger: 'blur' }
        ],
        password:[
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        business_license:[
          { required: true, message: '请上传营业执照', trigger: 'change' },
        ]
      },
      business_license: [],//营业执照
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传接口
      action: setting.commonurl + '/upload/business_license',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //营业执照
      businessLicenseupload_btn:false,
      businessLicensedialogVisible:false,
      businessLicensedialogImageUrl:'',

      //城市列表
      city:[],

      //自定义服务区域
      serve_district:[],
      // 服务区域是否修改
      xiugai:false,

      //司机相关
      // list:[
      //   {
      //     img: require('../../../assets/images/home/yunli/ziyingfuwushangactive.png'),
      //     name:'自营服务商'
      //   },
      //   {
      //     img: require('../../../assets/images/home/yunli/hezuofuwushang.png'),
      //     name:'合作服务商'
      //   },
      // ],
      // isShow:0,

    };
  },

  mounted() {
    //获取城市列表
    this.getCity();
  },
  methods: {
    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    //级联选择器
    handleChange(value) {
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = value[0] + '|' + areaTextArr[0];
      let city = value[1] + '|' + areaTextArr[1];
      let district = value[2] + '|' + areaTextArr[2];
      area.push(province,city,district);
      console.log(area)
      this.serve_district = area;
      this.xiugai = true;
    },

    // 营业执照
    businessLicensePreview(file){
      this.businessLicensedialogImageUrl = file.url;
      this.businessLicensedialogVisible = true;
    },
    businessLicensehandleRemove(file,fileList){
      console.log(file, fileList);
      this.businessLicenseupload_btn = false;
      if(fileList.length==0){
        this.rules.business_license = [{ required: true, message: '请上传营业执照', trigger: 'change'}];
      }
      this.business_license = [];
      this.form.business_license = '';
    },
    businessLicensechange(){
      this.businessLicenseupload_btn = true;
      this.$refs.form.clearValidate('business_license');
    },
    //上传成功
    successUpload2(file,fileList){
      console.log(file);
      console.log(fileList)
      // 上传成功后的列表
      this.business_license.push(file.data.urls)
      this.form.business_license = file.data.urls;

      // ocr 识别
      let data = {
        url: file.data.urls
      }
      ocr(data).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.form.company_name = res.data.companyName;
          this.form.realname = res.data.legalPerson;
          this.form.detailed_address = res.data.businessAddress;
          this.form.credit_code = res.data.creditCode
        }else {
          this.$message.error(res.msg);
        }
      })

    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        // 判断下是否上传了营业执照
        if(this.business_license.length != 0){
          this.$refs.form.clearValidate('business_license');
          delete this.rules.business_license
        }
        // 省市区
        if(this.xiugai == true){
          this.form.registration_place = this.serve_district
          this.$refs.form.clearValidate('business_license');
          delete this.rules.business_license
        }
        if (valid) {
          //创建
          Addsave_facilitator(this.form).then(res => {
            console.log(res)
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.$message.error(e.msg);
          })
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      if(item.name == '自营服务商'){
        this.list[0].img = require('../../../assets/images/home/yunli/ziyingfuwushangactive.png');
        this.list[1].img = require('../../../assets/images/home/yunli/hezuofuwushang.png');
        this.updateVisible(false);
        this.pleaseSelectAServiceProvider = true;
      }else if(item.name == '合作服务商'){
        this.list[0].img = require('../../../assets/images/home/yunli/ziyingfuwushang.png');
        this.list[1].img = require('../../../assets/images/home/yunli/hezuofuwushangactive.png');
        this.updateVisible(false);
        this.haveOrder = true;
      }
    },

    handleClose(){
      this.haveOrder = false;
      this.updateVisible(true);
    },

    //关闭请选择服务商
    handleClosepleaseSelectAServiceProvider(){
      this.pleaseSelectAServiceProvider = false;
      this.updateVisible(true);
    },


  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{

  .xuanxianglistactive{
    cursor: pointer;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 320px;
    height: 146px;
    font-size: 20px;
    font-weight: 500;
    color: #2d2f47;
    display: inline-block;
    margin: 10px;
  }
  .xuanxianglistactive1{
    color: #ff9b05;
  }

  //最后一个
  //.xuanxianglistactive:last-child{
  //  margin-right: 0;
  //}

}

.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
